import { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useMutation, useQuery } from 'urql'
import { DotsHorizontalIcon } from '@radix-ui/react-icons'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import { formatDate } from '@/utils/format-date'
import { useQueryParams } from '@/hooks/use-query-params'
import { SeasonSelector } from '@/components/season-selector'
import { Button } from '@/components/ui/button'
import { TableLayout } from '@/components/table-layout'
import { CreateAccountingExportDialog } from '@/components/new-accounting-export-dialog'
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { WarningAlert } from '@/components/warning-alert'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useToast } from '@/components/ui/use-toast'
import { SendCustomEmailDialog } from '@/components/send-custom-email-dialog'

const termsDocument = graphql(`
  query Terms($seasonId: ID!) {
    terms(seasonId: $seasonId) {
      id
      name
      startsAt
      endsAt
      maxParticipants
      acceptedParticipantCount
      totalParticipantCount
      hidden
    }
  }
`)

const enqueueTermMailersDocument = graphql(`
  mutation EnqueueTermMailers($input: EnqueueTermMailersInput!) {
    enqueueTermMailers(input: $input) {
      success
    }
  }
`)

export const Terms = () => {
  const { toast } = useToast()
  const [queryParams, setQueryParams] = useQueryParams()
  const [emailTermId, setEmailTermId] = useState<string | null>(null)
  const [accountingExportId, setAccountingExportId] = useState<string | null>(
    null
  )
  const [{ data, fetching, error }, refetch] = useQuery({
    query: termsDocument,
    variables: { seasonId: queryParams.seasonId },
    pause: !queryParams.seasonId,
  })

  const [{ fetching: submitting }, enqueueTermMailers] = useMutation(
    enqueueTermMailersDocument
  )

  const terms = data?.terms || []

  const handleEnqueueTermMailers = async (
    termIds: string[],
    mailer: string,
    args?: string[]
  ) => {
    const { error } = await enqueueTermMailers({
      input: { termIds, mailer, args },
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'E-post sendt',
      description: 'E-post er sendt til bekreftede bookinger i perioden.',
      variant: 'default',
    })
    setEmailTermId(null)
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <h1 className="text-4xl font-bold leading-loose">Perioder</h1>
        <Button asChild>
          <Link to={`new?seasonId=${queryParams.seasonId ?? ''}`}>
            Ny periode +
          </Link>
        </Button>
      </div>

      <div className="mb-4">
        <SeasonSelector
          selectedSeason={queryParams.seasonId}
          setSelectedSeason={(seasonId) =>
            setQueryParams((prev) => ({ ...prev, seasonId }))
          }
        />
      </div>

      <TableLayout>
        <TableHeader>
          <TableRow>
            <TableHead>#</TableHead>
            <TableHead>Dato</TableHead>
            <TableHead>Synlig</TableHead>
            <TableHead>Bookinger</TableHead>
            <TableHead>Bekreftet plasser</TableHead>
            <TableHead>Venteliste</TableHead>
            <TableHead>Ledige plasser</TableHead>
            <TableHead>Antall plasser</TableHead>
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>
          {terms.map((term) => {
            const placesLeft =
              term.maxParticipants - term.acceptedParticipantCount
            return (
              <TableRow key={term.id}>
                <TableCell>{term.name}</TableCell>
                <TableCell>
                  {formatDate(term.startsAt)} til {formatDate(term.endsAt)}
                </TableCell>
                <TableCell>{term.hidden ? 'Skjult' : 'Synlig'}</TableCell>
                <TableCell>{term.totalParticipantCount}</TableCell>
                <TableCell>{term.acceptedParticipantCount}</TableCell>
                <TableCell>
                  {term.totalParticipantCount - term.acceptedParticipantCount}
                </TableCell>
                <TableCell className={placesLeft < 0 ? 'text-red-600' : ''}>
                  {placesLeft}
                </TableCell>
                <TableCell>{term.maxParticipants}</TableCell>
                <TableCell className="py-1">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="sm">
                        <DotsHorizontalIcon className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem>
                        <Link
                          to={`${term.id}/edit?seasonId=${
                            queryParams.seasonId ?? ''
                          }`}
                        >
                          Endre periode
                        </Link>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        onClick={() => setAccountingExportId(term.id)}
                      >
                        Fakturer periode
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        disabled={submitting}
                        onClick={() =>
                          handleEnqueueTermMailers(
                            [term.id],
                            'creditLimitNoticeMailer'
                          )
                        }
                      >
                        Send Kioskpenger-info
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        disabled={submitting}
                        onClick={() => setEmailTermId(term.id)}
                      >
                        Send epost...
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </TableLayout>

      {fetching && <Loading />}
      {error && <QueryError error={error} />}
      {terms.length === 0 && !fetching && !error && (
        <WarningAlert
          title="Ingen resultater"
          description="Ingen perioder ble funnet."
        />
      )}

      {accountingExportId && (
        <CreateAccountingExportDialog
          termId={accountingExportId}
          onClose={() => setAccountingExportId(null)}
        />
      )}

      {emailTermId && (
        <SendCustomEmailDialog
          description={terms.find((term) => term.id === emailTermId)?.name}
          submitting={submitting}
          onClose={() => setEmailTermId(null)}
          onSubmit={(data) => {
            handleEnqueueTermMailers([emailTermId], 'customMessageMailer', [
              data.subject,
              data.body,
            ])
          }}
        />
      )}

      <Outlet context={{ refetch }} />
    </>
  )
}
