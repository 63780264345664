import { useEffect, useRef } from 'react'
import Quill from 'quill'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

type TextEditorProps = {
  value: string
  onChange: (value: string) => void
}

export function TextEditor({ value, onChange }: TextEditorProps) {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const container = containerRef.current
    if (!container) return
    const editorContainer = container.appendChild(
      container.ownerDocument.createElement('div')
    )
    const quill = new Quill(editorContainer, {
      theme: 'snow',
      bounds: '.text-editor',
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          ['link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ color: [] }],
        ],
      },
    })

    if (value) {
      quill.setContents(quill.clipboard.convert({ html: value }))
    }

    quill.on(Quill.events.TEXT_CHANGE, () => {
      onChange(quill.getSemanticHTML())
    })

    return () => {
      container.innerHTML = ''
    }
  }, [])

  return <div ref={containerRef} className="text-editor" />
}
