import { graphql } from '@/__generated__/gql'
import { AttendeeInfo, AttendeeInfoProps } from './attendee-info'
import { BookingsTable } from './bookings-table'
import { PaymentsInfo } from './payments-info'
import { CustomerTable } from './customer-table'
import { ContactPersonTable } from './contact-person-table'
import { BookingExportsInfo } from './booking-exports-info'

export const bookingInfoDocument = graphql(`
  fragment BookingInfo on Booking {
    ...AttendeeInfo
    ...PaymentsInfo
    ...BookingExportsInfo
    order {
      id
      customer {
        ...CustomerTableRow
      }
      contactPersons {
        ...ContactPersonTableRow
      }
      bookings {
        ...BookingsTableRow
      }
    }
  }
`)

export const BookingInfo = ({ booking }: AttendeeInfoProps) => {
  return (
    <>
      <AttendeeInfo booking={booking} />

      <div className="mt-8">
        <h2 className="font-bold text-xl mb-2">Foresatt</h2>
        <CustomerTable customer={booking.order.customer} />
      </div>

      <ContactPersonTable
        contactPersons={booking.order.contactPersons}
        orderId={booking.order.id}
      />

      {booking.payments.length > 0 && (
        <PaymentsInfo payments={booking.payments} />
      )}

      <BookingExportsInfo bookingExports={booking.bookingExports} />

      {booking.order.bookings.length > 1 && (
        <div className="mt-8">
          <h2 className="font-bold text-xl mb-4">Bookinger i samme ordre</h2>
          <BookingsTable
            bookings={booking.order.bookings.filter(
              (orderBooking) => orderBooking.id !== booking.id
            )}
          />
        </div>
      )}
    </>
  )
}
