import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'urql'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'

import { toast } from '@/components/ui/use-toast'
import { ContactPersonForm, FormValues } from './components/contact-person-form'

const createContactPersonDocument = graphql(`
  mutation CreateContactPerson($input: ContactPersonInput!) {
    createContactPerson(input: $input) {
      order {
        id
        contactPersons {
          id
          firstName
          lastName
          email
          phone
        }
      }
    }
  }
`)

export const CreateContactPersonDialog = () => {
  const params = useParams<'orderId'>()
  const navigate = useNavigate()
  const [{ fetching: submitting }, createContactPerson] = useMutation(
    createContactPersonDocument
  )

  const onClose = () => navigate('..')
  const onSubmit = async (formInput: FormValues) => {
    const input = { orderId: params.orderId as string, ...formInput }
    const { error } = await createContactPerson({
      input,
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Kontaktperson er opprettet',
      variant: 'default',
    })
    onClose()
  }

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <>
          <DialogHeader>
            <DialogTitle>Rediger foresatt</DialogTitle>
            <DialogDescription />
          </DialogHeader>
          <ContactPersonForm onSubmit={onSubmit} />
          <DialogFooter>
            <Button type="button" variant="secondary" onClick={onClose}>
              Avbryt
            </Button>
            <Button
              form="contact-person-form"
              type="submit"
              disabled={submitting}
            >
              Opprett
            </Button>
          </DialogFooter>
        </>
      </DialogContent>
    </Dialog>
  )
}
