import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Button } from './ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from './ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from './ui/form'
import { Input } from './ui/input'
import { TextEditor } from './text-editor'

type SendCustomEmailProps = {
  submitting: boolean
  description?: string
  onSubmit: (data: FormValues) => Promise<void> | void
  onClose: () => void
}

const formSchema = z.object({
  subject: z
    .string({ errorMap: () => ({ message: 'Emne må fylles ut' }) })
    .min(2),
  body: z
    .string({ errorMap: () => ({ message: 'Melding må fylles ut' }) })
    .min(2),
})

export type FormValues = z.infer<typeof formSchema>

export function SendCustomEmailDialog({
  description,
  submitting,
  onSubmit,
  onClose,
}: SendCustomEmailProps) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      subject: '',
      body: '',
    },
  })

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent
        className="max-w-xl"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
      >
        <DialogHeader>
          <DialogTitle>Send e-post</DialogTitle>
          <DialogDescription>
            {description && (
              <>Til kontaktpersoner med bekreftet booking i {description}</>
            )}
            {!description && 'Til kontaktpersoner'}
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            id="send-custom-email-form"
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <fieldset className="flex flex-col gap-5">
              <FormField
                control={form.control}
                name="subject"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>Emne</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="body"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>Melding</FormLabel>
                    <FormControl>
                      <TextEditor
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </fieldset>
          </form>
        </Form>

        <DialogFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            Avbryt
          </Button>
          <Button
            form="send-custom-email-form"
            type="submit"
            disabled={submitting}
          >
            Send
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
