import { useMutation, useQuery } from 'urql'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { Input } from '@/components/ui/input'

type Props = {
  termId?: string
  bookingId?: string
  onClose: () => void
}

const formSchema = z.object({
  invoiceDate: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
})

export type FormValues = z.infer<typeof formSchema>

const previewAccountingExportDocument = graphql(`
  query PreviewAccountingExportDocument($input: PreviewAccountingExportInput!) {
    previewAccountingExport(input: $input) {
      totalCount
      description
    }
  }
`)

const createAccountingExportDocument = graphql(`
  mutation CreateAccountingExport($input: AccountingExportInput!) {
    createAccountingExport(input: $input) {
      accountingExport {
        id
      }
    }
  }
`)

export const CreateAccountingExportDialog = ({
  termId,
  bookingId,
  onClose,
}: Props) => {
  const { toast } = useToast()
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      invoiceDate: new Date().toISOString().split('T')[0],
    },
  })

  const input = {
    ...(termId ? { termIds: [termId] } : {}),
    ...(bookingId ? { bookingIds: [bookingId] } : {}),
  }

  const [{ data, fetching }] = useQuery({
    query: previewAccountingExportDocument,
    variables: { input },
  })
  const [{ fetching: submitting }, createAccountingExport] = useMutation(
    createAccountingExportDocument
  )

  const onSubmit = async (data: FormValues) => {
    const { error } = await createAccountingExport({
      input: { ...input, ...data },
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Eksport opprettet',
      description: 'Bookinger er lagt i kø for eksport til regnskapssystem',
      variant: 'default',
    })
    onClose()
  }

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Eksport til regnskapssystem</DialogTitle>
          <DialogDescription>
            {data?.previewAccountingExport.description || ''}
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            id="new-accounting-export-form"
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <fieldset className="flex flex-col gap-5">
              <FormField
                control={form.control}
                name="invoiceDate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>Fakturadato</FormLabel>
                    <FormControl>
                      <Input type="date" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </fieldset>
          </form>
        </Form>

        <DialogFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            Avbryt
          </Button>
          <Button
            form="new-accounting-export-form"
            type="submit"
            disabled={fetching || submitting}
          >
            Fakturer {data?.previewAccountingExport.totalCount || ''}{' '}
            booking(er)
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
