import { useMutation } from 'urql'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import { useQueryParams } from '@/hooks/use-query-params'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { FormValues, TermForm } from './components/term-form'

const createTermDocument = graphql(`
  mutation CreateTerm($input: TermInput!) {
    createTerm(input: $input) {
      term {
        id
        name
      }
    }
  }
`)

export const NewTerm = () => {
  const { refetch } = useOutletContext<{ refetch: () => void }>()
  const [queryParams] = useQueryParams()
  const navigate = useNavigate()
  const { toast } = useToast()
  const [_, createTerm] = useMutation(createTermDocument)

  const onClose = () => navigate(`../?seasonId=${queryParams.seasonId}`)
  const onSubmit = async (values: FormValues) => {
    const { error } = await createTerm({
      input: {
        ...values,
        seasonId: queryParams.seasonId,
        startsAt: new Date(values.startsAt).toISOString(),
        endsAt: new Date(values.endsAt).toISOString(),
      },
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Periode opprettet',
      description: 'Ny periode ble opprettet',
      variant: 'default',
    })
    refetch()
    onClose()
  }

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Ny periode</DialogTitle>
          <DialogDescription />
        </DialogHeader>

        <TermForm
          onSubmit={onSubmit}
          defaultValues={{
            name: '',
            startsAt: '',
            endsAt: '',
            hidden: false,
          }}
        />

        <DialogFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            Avbryt
          </Button>
          <Button form="term-form" type="submit">
            Opprett
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
