import { useState } from 'react'
import { useMutation } from 'urql'
import { graphql } from '@/__generated__/gql'
import { ResendMailDropdownFragment } from '@/__generated__/gql/graphql'
import { phrases } from '@/utils/phrases'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { SendCustomEmailDialog } from '@/components/send-custom-email-dialog'

type Props = {
  booking: ResendMailDropdownFragment
}

export const resendMailDocument = graphql(`
  fragment ResendMailDropdown on Booking {
    id
    status
    reservation {
      id
      accepted
    }
    order {
      id
    }
  }
`)

const enqueueMailerDocument = graphql(`
  mutation EnqueueMailer($input: EnqueueMailerInput!) {
    enqueueMailer(input: $input) {
      success
    }
  }
`)

export const ResendMailDropdown = ({ booking }: Props) => {
  const [sendCustomMessage, setSendCustomMessage] = useState(false)
  const [{ fetching: submitting }, enqueueMailer] = useMutation(
    enqueueMailerDocument
  )
  const { toast } = useToast()

  const handleSendMail = async ({
    mailer,
    args,
    name,
  }: { mailer: string; args: string[]; name: string }) => {
    const { error } = await enqueueMailer({
      input: { mailer, args },
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'E-post sendt',
      description: `${name} er sendt til kontaktpersoner`,
      variant: 'default',
    })
    setSendCustomMessage(false)
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="secondary" size="sm">
            Send e-post
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {booking.status !== 'canceled' && (
            <>
              <DropdownMenuItem
                onClick={() =>
                  handleSendMail({
                    mailer: 'orderConfirmationMailer',
                    args: [booking.order.id],
                    name: 'Ordrebekreftelse',
                  })
                }
              >
                Ordrebekreftelse
              </DropdownMenuItem>
              {booking.reservation.accepted && (
                <DropdownMenuItem
                  onClick={() =>
                    handleSendMail({
                      mailer: 'bookingConfirmationMailer',
                      args: [booking.id],
                      name: 'Bookingbekreftelse',
                    })
                  }
                >
                  Bookingbekreftelse
                </DropdownMenuItem>
              )}
              <DropdownMenuItem
                onClick={() =>
                  handleSendMail({
                    mailer: 'creditLimitNoticeMailer',
                    args: [booking.id],
                    name: 'Kioskpenger-info',
                  })
                }
              >
                Kioskpenger-info
              </DropdownMenuItem>
            </>
          )}
          {booking.status === 'canceled' && (
            <DropdownMenuItem
              onClick={() =>
                handleSendMail({
                  mailer: 'bookingCanceledMailer',
                  args: [booking.id],
                  name: 'Avbestillingsbekreftelse',
                })
              }
            >
              Avbestillingsbekreftelse
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            disabled={submitting}
            onClick={() => setSendCustomMessage(true)}
          >
            Send epost...
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      {sendCustomMessage && (
        <SendCustomEmailDialog
          description={''}
          submitting={submitting}
          onClose={() => setSendCustomMessage(false)}
          onSubmit={(data) => {
            handleSendMail({
              mailer: 'customMessageMailer',
              args: [booking.id, data.subject, data.body],
              name: 'E-post',
            })
          }}
        />
      )}
    </>
  )
}
