import { useMutation, useQuery } from 'urql'
import { useNavigate, useParams } from 'react-router-dom'
import { formatInTimeZone } from 'date-fns-tz'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import { useQueryParams } from '@/hooks/use-query-params'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { FormValues, TermForm } from './components/term-form'

const editTermDocument = graphql(`
  query EditTerm($id: ID!) {
    term(id: $id) {
      id
      name
      startsAt
      endsAt
      price
      deposit
      targetGroup
      maxParticipants
      hidden
    }
  }
`)

const updateTermDocument = graphql(`
  mutation UpdateTerm($id: ID!, $input: TermInput!) {
    updateTerm(id: $id, input: $input) {
      term {
        id
        name
        startsAt
        endsAt
        price
        deposit
        targetGroup
        maxParticipants
        hidden
      }
    }
  }
`)

export const EditTerm = () => {
  const navigate = useNavigate()
  const params = useParams<'termId'>()
  const [queryParams] = useQueryParams()
  const { toast } = useToast()
  const [{ data, fetching, error }] = useQuery({
    query: editTermDocument,
    variables: { id: params.termId! },
  })
  const [{ fetching: submitting }, updateTerm] = useMutation(updateTermDocument)

  const onClose = () => navigate(`../?seasonId=${queryParams.seasonId}`)
  const onSubmit = async (values: FormValues) => {
    const { error } = await updateTerm({
      id: params.termId!,
      input: {
        ...values,
        startsAt: new Date(values.startsAt).toISOString(),
        endsAt: new Date(values.endsAt).toISOString(),
      },
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Periode endret',
      description: 'Perioden ble endret',
      variant: 'default',
    })
    onClose()
  }

  const loading = fetching || submitting
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Endre periode</DialogTitle>
          <DialogDescription />
        </DialogHeader>
        {fetching && <Loading />}
        {error && <QueryError error={error} />}
        {data && (
          <TermForm
            onSubmit={onSubmit}
            defaultValues={{
              name: data.term.name,
              startsAt: formatInTimeZone(
                new Date(data.term.startsAt),
                timeZone,
                "yyyy-MM-dd'T'HH:mm"
              ),
              endsAt: formatInTimeZone(
                new Date(data.term.endsAt),
                timeZone,
                "yyyy-MM-dd'T'HH:mm"
              ),
              price: data.term.price,
              deposit: data.term.deposit,
              targetGroup: data.term.targetGroup,
              maxParticipants: data.term.maxParticipants,
              hidden: data.term.hidden,
            }}
          />
        )}

        <DialogFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            Avbryt
          </Button>
          <Button form="term-form" type="submit" disabled={loading}>
            Lagre
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
