import React from 'react'
import { useMutation } from 'urql'
import { DotsHorizontalIcon } from '@radix-ui/react-icons'
import { graphql } from '@/__generated__/gql'
import { CancelOption, PaymentsInfoFragment } from '@/__generated__/gql/graphql'
import { formatDate } from '@/utils/format-date'
import { formatCurrency } from '@/utils/format-currency'
import { phrases } from '@/utils/phrases'
import { TableLayout } from '@/components/table-layout'
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useToast } from '@/components/ui/use-toast'

export const paymentsInfoDocument = graphql(`
  fragment PaymentsInfo on Booking {
    payments {
      id
      state
      amount
      provider
      externalRef
      externalUrl
      cancellationOptions
      updatedAt
    }
  }
`)

const cancelPaymentDocument = graphql(`
  mutation CancelPayment($id: ID!, $option: CancelOption!) {
    cancelPayment(id: $id, option: $option) {
      payment {
        id
        state
      }
    }
  }
`)

export type PaymentsInfoProps = {
  payments: PaymentsInfoFragment['payments']
}

export const PaymentsInfo = ({ payments }: PaymentsInfoProps) => {
  const { toast } = useToast()
  const [{ fetching }, cancelPayment] = useMutation(cancelPaymentDocument)

  const handleCancelPayment = async (id: string, option: CancelOption) => {
    if (
      !confirm(
        `Er du sikker på at du vil ${option === 'refundPayment' ? 'refundere' : 'kanellere'} denne betalingen?`
      )
    ) {
      return
    }

    const { error } = await cancelPayment({
      id,
      option,
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Betaling oppdatert!',
      description:
        option === 'refundPayment'
          ? 'Betalingen ble refundert'
          : 'Betalingen ble kansellert',
      variant: 'default',
    })
  }

  return (
    <div>
      <h2 className="font-bold text-xl mb-2">Betalinger</h2>
      <TableLayout>
        <TableHeader>
          <TableRow>
            <TableHead>Leverandør</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Beløp</TableHead>
            <TableHead>Dato</TableHead>
            <TableHead>Referanse</TableHead>
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>
          {payments.map((payment) => {
            const cancellationOptions = payment.cancellationOptions
            return (
              <TableRow key={payment.id}>
                <TableCell>{payment.provider}</TableCell>
                <TableCell>{payment.state}</TableCell>
                <TableCell>{formatCurrency(payment.amount)}</TableCell>
                <TableCell>{formatDate(payment.updatedAt)}</TableCell>
                <TableCell>{payment.externalRef}</TableCell>
                <TableCell className="py-1">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="sm">
                        <DotsHorizontalIcon className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      {payment.externalUrl && (
                        <>
                          <DropdownMenuItem>
                            <a
                              href={payment.externalUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Vis betaling hos leverandør
                            </a>
                          </DropdownMenuItem>
                          <DropdownMenuSeparator />
                        </>
                      )}

                      {cancellationOptions.map((option) => (
                        <React.Fragment key={option}>
                          <DropdownMenuItem
                            disabled={fetching}
                            onClick={() =>
                              handleCancelPayment(payment.id, option)
                            }
                          >
                            {phrases.bookingCancellationOptions[option]}
                          </DropdownMenuItem>
                          <DropdownMenuSeparator />
                        </React.Fragment>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </TableLayout>
    </div>
  )
}
