/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query PreviewAccountingExportDocument($input: PreviewAccountingExportInput!) {\n    previewAccountingExport(input: $input) {\n      totalCount\n      description\n    }\n  }\n": types.PreviewAccountingExportDocumentDocument,
    "\n  mutation CreateAccountingExport($input: AccountingExportInput!) {\n    createAccountingExport(input: $input) {\n      accountingExport {\n        id\n      }\n    }\n  }\n": types.CreateAccountingExportDocument,
    "\n  query Seasons {\n    seasons {\n      id\n      name\n    }\n  }\n": types.SeasonsDocument,
    "\n  query TermSelector($seasonId: ID!) {\n    terms(seasonId: $seasonId) {\n      id\n      name\n    }\n  }\n": types.TermSelectorDocument,
    "\n  query ViewerContextProvider {\n    viewer {\n      id\n      user {\n        id\n        name\n        roles\n      }\n      canMutateBookings: can(action: \"mutate\", resource: \"Bookings\")\n    }\n  }\n": types.ViewerContextProviderDocument,
    "\n  query AccountingExportsTable($input: ConnectionInputArgs!) {\n    accountingExports(input: $input) {\n      totalCount\n      results {\n        id\n        description\n        bookingsCount\n        bookingsCompletedCount\n        createdAt\n      }\n    }\n  }\n": types.AccountingExportsTableDocument,
    "\n  query ConfirmBookingDialog($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      reservation {\n        id\n        accepted\n      }\n      term {\n        id\n        maxParticipants\n        acceptedParticipantCount\n      }\n    }\n  }\n": types.ConfirmBookingDialogDocument,
    "\n  mutation AcceptBooking($input: AcceptBookingInput!) {\n    acceptBooking(input: $input) {\n      booking {\n        id\n        status\n        reservation {\n          id\n          accepted\n        }\n      }\n    }\n  }\n": types.AcceptBookingDocument,
    "\n  query Booking($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      number\n      status\n      activeBookingExport {\n        id\n        state\n      }\n      ...BookingInfo\n      ...ResendMailDropdown\n    }\n  }\n": types.BookingDocument,
    "\n  fragment AttendeeInfo on Booking {\n    id\n    status\n    firstName\n    lastName\n    dob\n    pastVisits\n    gender\n    friends\n    notes\n    sweaterSize\n    price\n    discount\n    creditLimit\n    isFullyPaid\n    createdAt\n    updatedAt\n    activeBookingExport {\n      id\n      state\n      invoicePaidAt\n      creditNoteRef\n    }\n    term {\n      id\n      name\n      startsAt\n      endsAt\n    }\n    reservation {\n      id\n      sequence\n    }\n  }\n": types.AttendeeInfoFragmentDoc,
    "\n  fragment BookingExportsInfo on Booking {\n    bookingExports {\n      id\n      state\n      exportedAmount\n      invoiceTotal\n      invoicePaidAt\n      invoiceNumber\n      createdAt\n    }\n  }\n": types.BookingExportsInfoFragmentDoc,
    "\n  mutation CreditBookingExport($id: ID!) {\n    creditBookingExport(id: $id) {\n      bookingExport {\n        id\n        state\n      }\n    }\n  }\n": types.CreditBookingExportDocument,
    "\n  fragment BookingInfo on Booking {\n    ...AttendeeInfo\n    ...PaymentsInfo\n    ...BookingExportsInfo\n    order {\n      id\n      customer {\n        ...CustomerTableRow\n      }\n      contactPersons {\n        ...ContactPersonTableRow\n      }\n      bookings {\n        ...BookingsTableRow\n      }\n    }\n  }\n": types.BookingInfoFragmentDoc,
    "\n  fragment BookingsTableRow on Booking {\n    id\n    number\n    status\n    firstName\n    lastName\n    createdAt\n    dob\n    pastVisits\n    sweaterSize\n    discount\n    isInvoiced\n    term {\n      id\n      name\n      targetGroup\n      startsAt\n      endsAt\n    }\n  }\n": types.BookingsTableRowFragmentDoc,
    "\n  mutation SetBookingDiscount($id: ID!, $input: UpdateBookingInput!) {\n    updateBooking(id: $id, input: $input) {\n      booking {\n        id\n        discount\n      }\n    }\n  }\n": types.SetBookingDiscountDocument,
    "\n  query Bookings($input: BookingsConnectionInputArgs!) {\n    bookings(input: $input) {\n      totalCount\n      results {\n        id\n        ...BookingsTableRow\n      }\n    }\n  }\n": types.BookingsDocument,
    "\n  query CancelBookingDialog($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      cancellationOptions\n    }\n  }\n": types.CancelBookingDialogDocument,
    "\n  mutation CancelBooking($input: CancelBookingInput!) {\n    cancelBooking(input: $input) {\n      booking {\n        id\n        status\n      }\n    }\n  }\n": types.CancelBookingDocument,
    "\n  fragment ContactPersonTableRow on ContactPerson {\n    id\n    firstName\n    lastName\n    email\n    phone\n  }\n": types.ContactPersonTableRowFragmentDoc,
    "\n  fragment CustomerTableRow on Customer {\n    id\n    firstName\n    lastName\n    email\n    phone\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n  }\n": types.CustomerTableRowFragmentDoc,
    "\n  mutation DeleteContactPerson($id: ID!, $signature: String) {\n    deleteContactPerson(id: $id, signature: $signature) {\n      order {\n        id\n        contactPersons {\n          id\n          firstName\n          lastName\n          email\n          phone\n        }\n      }\n    }\n  }\n": types.DeleteContactPersonDocument,
    "\n  fragment PaymentsInfo on Booking {\n    payments {\n      id\n      state\n      amount\n      provider\n      externalRef\n      externalUrl\n      cancellationOptions\n      updatedAt\n    }\n  }\n": types.PaymentsInfoFragmentDoc,
    "\n  mutation CancelPayment($id: ID!, $option: CancelOption!) {\n    cancelPayment(id: $id, option: $option) {\n      payment {\n        id\n        state\n      }\n    }\n  }\n": types.CancelPaymentDocument,
    "\n  fragment ResendMailDropdown on Booking {\n    id\n    status\n    reservation {\n      id\n      accepted\n    }\n    order {\n      id\n    }\n  }\n": types.ResendMailDropdownFragmentDoc,
    "\n  mutation EnqueueMailer($input: EnqueueMailerInput!) {\n    enqueueMailer(input: $input) {\n      success\n    }\n  }\n": types.EnqueueMailerDocument,
    "\n  mutation UncancelBooking($input: UncancelBookingInput!) {\n    uncancelBooking(input: $input) {\n      booking {\n        id\n        status\n        reservation {\n          id\n          accepted\n          sequence\n        }\n      }\n    }\n  }\n": types.UncancelBookingDocument,
    "\n  mutation CreateContactPerson($input: ContactPersonInput!) {\n    createContactPerson(input: $input) {\n      order {\n        id\n        contactPersons {\n          id\n          firstName\n          lastName\n          email\n          phone\n        }\n      }\n    }\n  }\n": types.CreateContactPersonDocument,
    "\n  query updateBookingInfo($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      firstName\n      lastName\n      dob\n      gender\n      pastVisits\n      friends\n      notes\n      sweaterSize\n      creditLimit\n      price\n      discount\n    }\n  }\n": types.UpdateBookingInfoDocument,
    "\n  mutation updateBooking($id: ID!, $input: UpdateBookingInput!) {\n    updateBooking(id: $id, input: $input) {\n      booking {\n        ...BookingInfo\n      }\n    }\n  }\n": types.UpdateBookingDocument,
    "\n  fragment UpdateCustomerData on Customer {\n    id\n    firstName\n    lastName\n    email\n    phone\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n  }\n": types.UpdateCustomerDataFragmentDoc,
    "\n  query UpdateCustomerData($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      order {\n        id\n        customer {\n          id\n          ...UpdateCustomerData\n        }\n      }\n    }\n  }\n": types.UpdateCustomerDataDocument,
    "\n  mutation UpdateCustomer($orderId: ID!, $input: CustomerInput!) {\n    updateCustomer(orderId: $orderId, input: $input) {\n      customer {\n        id\n        ...UpdateCustomerData\n      }\n    }\n  }\n": types.UpdateCustomerDocument,
    "\n  query changeTermDialog($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      term {\n        id\n        season {\n          id\n          terms{\n            id\n            name\n            acceptedParticipantCount\n            maxParticipants\n          }\n        }\n      }\n    }\n  }\n": types.ChangeTermDialogDocument,
    "\n  mutation UpdateTermBooking($input: ChangeTermInput!) {\n    changeTerm(input: $input) {\n      booking {\n        id\n        status\n        reservation {\n          id\n          accepted\n        }\n      }\n    }\n  }\n": types.UpdateTermBookingDocument,
    "\n  mutation RequestOtp($input: OtpInput!) {\n    requestOtp(input: $input) {\n      viewer {\n        id\n      }\n    }\n  }\n": types.RequestOtpDocument,
    "\n  mutation Login($input: LoginInput!) {\n    login(input: $input) {\n      viewer {\n        id\n        user {\n          id\n        }\n      }\n    }\n  }\n": types.LoginDocument,
    "\n  query EditSeason($id: ID!) {\n    season(id: $id) {\n      id\n      name\n      registrationOpensAt\n      canSetSweaterSize\n      canSetCreditLimit\n    }\n  }\n": types.EditSeasonDocument,
    "\n  mutation UpdateSeason($id: ID!, $input: SeasonInput!) {\n    updateSeason(id: $id, input: $input) {\n      season {\n        id\n        name\n        registrationOpensAt\n        canSetSweaterSize\n        canSetCreditLimit\n      }\n    }\n  }\n": types.UpdateSeasonDocument,
    "\n  mutation CreateSeason($input: SeasonInput!) {\n    createSeason(input: $input) {\n      season {\n        id\n        name\n      }\n    }\n  }\n": types.CreateSeasonDocument,
    "\n  query Season($seasonId: ID!) {\n    season(id: $seasonId) {\n      id\n      name\n      registrationOpensAt\n      active\n      canSetSweaterSize\n      canSetCreditLimit\n    }\n  }\n": types.SeasonDocument,
    "\n  mutation ActivateSeason($id: ID!) {\n    activateSeason(id: $id) {\n      season {\n        id\n        active\n      }\n    }\n  }\n\n": types.ActivateSeasonDocument,
    "\n  query SeasonsTable {\n    seasons {\n      id\n      name\n      registrationOpensAt\n      active\n    }\n  }\n": types.SeasonsTableDocument,
    "\n  mutation EnqueueSeasonMailers($input: EnqueueSeasonMailersInput!) {\n    enqueueSeasonMailers(input: $input) {\n      success\n    }\n  }\n": types.EnqueueSeasonMailersDocument,
    "\n  query EditTerm($id: ID!) {\n    term(id: $id) {\n      id\n      name\n      startsAt\n      endsAt\n      price\n      deposit\n      targetGroup\n      maxParticipants\n      hidden\n    }\n  }\n": types.EditTermDocument,
    "\n  mutation UpdateTerm($id: ID!, $input: TermInput!) {\n    updateTerm(id: $id, input: $input) {\n      term {\n        id\n        name\n        startsAt\n        endsAt\n        price\n        deposit\n        targetGroup\n        maxParticipants\n        hidden\n      }\n    }\n  }\n": types.UpdateTermDocument,
    "\n  mutation CreateTerm($input: TermInput!) {\n    createTerm(input: $input) {\n      term {\n        id\n        name\n      }\n    }\n  }\n": types.CreateTermDocument,
    "\n  query Terms($seasonId: ID!) {\n    terms(seasonId: $seasonId) {\n      id\n      name\n      startsAt\n      endsAt\n      maxParticipants\n      acceptedParticipantCount\n      totalParticipantCount\n      hidden\n    }\n  }\n": types.TermsDocument,
    "\n  mutation EnqueueTermMailers($input: EnqueueTermMailersInput!) {\n    enqueueTermMailers(input: $input) {\n      success\n    }\n  }\n": types.EnqueueTermMailersDocument,
    "\n  query UserFormQuery {\n    availableUserRoles\n    activeTerms {\n      id\n      name\n    }\n  }\n": types.UserFormQueryDocument,
    "\n  query EditUser($id: ID!) {\n    user(id: $id) {\n      id\n      name\n      email\n      phone\n      active\n      roles\n      terms {\n        id\n      }\n    }\n  }\n": types.EditUserDocument,
    "\n  mutation UpdateUser($id: ID!, $input: UserInput!) {\n    updateUser(id: $id, input: $input) {\n      user {\n        id\n        name\n        email\n        phone\n        active\n        roles\n        terms {\n          id\n        }\n      }\n    }\n  }\n": types.UpdateUserDocument,
    "\n  mutation CreateUser($input: UserInput!) {\n    createUser(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n": types.CreateUserDocument,
    "\n  query Users($input: UsersConnectionInputArgs!) {\n    users(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        email\n        phone\n        active\n        roles\n      }\n    }\n  }\n": types.UsersDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PreviewAccountingExportDocument($input: PreviewAccountingExportInput!) {\n    previewAccountingExport(input: $input) {\n      totalCount\n      description\n    }\n  }\n"): (typeof documents)["\n  query PreviewAccountingExportDocument($input: PreviewAccountingExportInput!) {\n    previewAccountingExport(input: $input) {\n      totalCount\n      description\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateAccountingExport($input: AccountingExportInput!) {\n    createAccountingExport(input: $input) {\n      accountingExport {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAccountingExport($input: AccountingExportInput!) {\n    createAccountingExport(input: $input) {\n      accountingExport {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Seasons {\n    seasons {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query Seasons {\n    seasons {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TermSelector($seasonId: ID!) {\n    terms(seasonId: $seasonId) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query TermSelector($seasonId: ID!) {\n    terms(seasonId: $seasonId) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ViewerContextProvider {\n    viewer {\n      id\n      user {\n        id\n        name\n        roles\n      }\n      canMutateBookings: can(action: \"mutate\", resource: \"Bookings\")\n    }\n  }\n"): (typeof documents)["\n  query ViewerContextProvider {\n    viewer {\n      id\n      user {\n        id\n        name\n        roles\n      }\n      canMutateBookings: can(action: \"mutate\", resource: \"Bookings\")\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AccountingExportsTable($input: ConnectionInputArgs!) {\n    accountingExports(input: $input) {\n      totalCount\n      results {\n        id\n        description\n        bookingsCount\n        bookingsCompletedCount\n        createdAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query AccountingExportsTable($input: ConnectionInputArgs!) {\n    accountingExports(input: $input) {\n      totalCount\n      results {\n        id\n        description\n        bookingsCount\n        bookingsCompletedCount\n        createdAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ConfirmBookingDialog($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      reservation {\n        id\n        accepted\n      }\n      term {\n        id\n        maxParticipants\n        acceptedParticipantCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query ConfirmBookingDialog($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      reservation {\n        id\n        accepted\n      }\n      term {\n        id\n        maxParticipants\n        acceptedParticipantCount\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AcceptBooking($input: AcceptBookingInput!) {\n    acceptBooking(input: $input) {\n      booking {\n        id\n        status\n        reservation {\n          id\n          accepted\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AcceptBooking($input: AcceptBookingInput!) {\n    acceptBooking(input: $input) {\n      booking {\n        id\n        status\n        reservation {\n          id\n          accepted\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Booking($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      number\n      status\n      activeBookingExport {\n        id\n        state\n      }\n      ...BookingInfo\n      ...ResendMailDropdown\n    }\n  }\n"): (typeof documents)["\n  query Booking($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      number\n      status\n      activeBookingExport {\n        id\n        state\n      }\n      ...BookingInfo\n      ...ResendMailDropdown\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AttendeeInfo on Booking {\n    id\n    status\n    firstName\n    lastName\n    dob\n    pastVisits\n    gender\n    friends\n    notes\n    sweaterSize\n    price\n    discount\n    creditLimit\n    isFullyPaid\n    createdAt\n    updatedAt\n    activeBookingExport {\n      id\n      state\n      invoicePaidAt\n      creditNoteRef\n    }\n    term {\n      id\n      name\n      startsAt\n      endsAt\n    }\n    reservation {\n      id\n      sequence\n    }\n  }\n"): (typeof documents)["\n  fragment AttendeeInfo on Booking {\n    id\n    status\n    firstName\n    lastName\n    dob\n    pastVisits\n    gender\n    friends\n    notes\n    sweaterSize\n    price\n    discount\n    creditLimit\n    isFullyPaid\n    createdAt\n    updatedAt\n    activeBookingExport {\n      id\n      state\n      invoicePaidAt\n      creditNoteRef\n    }\n    term {\n      id\n      name\n      startsAt\n      endsAt\n    }\n    reservation {\n      id\n      sequence\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BookingExportsInfo on Booking {\n    bookingExports {\n      id\n      state\n      exportedAmount\n      invoiceTotal\n      invoicePaidAt\n      invoiceNumber\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  fragment BookingExportsInfo on Booking {\n    bookingExports {\n      id\n      state\n      exportedAmount\n      invoiceTotal\n      invoicePaidAt\n      invoiceNumber\n      createdAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreditBookingExport($id: ID!) {\n    creditBookingExport(id: $id) {\n      bookingExport {\n        id\n        state\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreditBookingExport($id: ID!) {\n    creditBookingExport(id: $id) {\n      bookingExport {\n        id\n        state\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BookingInfo on Booking {\n    ...AttendeeInfo\n    ...PaymentsInfo\n    ...BookingExportsInfo\n    order {\n      id\n      customer {\n        ...CustomerTableRow\n      }\n      contactPersons {\n        ...ContactPersonTableRow\n      }\n      bookings {\n        ...BookingsTableRow\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment BookingInfo on Booking {\n    ...AttendeeInfo\n    ...PaymentsInfo\n    ...BookingExportsInfo\n    order {\n      id\n      customer {\n        ...CustomerTableRow\n      }\n      contactPersons {\n        ...ContactPersonTableRow\n      }\n      bookings {\n        ...BookingsTableRow\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BookingsTableRow on Booking {\n    id\n    number\n    status\n    firstName\n    lastName\n    createdAt\n    dob\n    pastVisits\n    sweaterSize\n    discount\n    isInvoiced\n    term {\n      id\n      name\n      targetGroup\n      startsAt\n      endsAt\n    }\n  }\n"): (typeof documents)["\n  fragment BookingsTableRow on Booking {\n    id\n    number\n    status\n    firstName\n    lastName\n    createdAt\n    dob\n    pastVisits\n    sweaterSize\n    discount\n    isInvoiced\n    term {\n      id\n      name\n      targetGroup\n      startsAt\n      endsAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetBookingDiscount($id: ID!, $input: UpdateBookingInput!) {\n    updateBooking(id: $id, input: $input) {\n      booking {\n        id\n        discount\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetBookingDiscount($id: ID!, $input: UpdateBookingInput!) {\n    updateBooking(id: $id, input: $input) {\n      booking {\n        id\n        discount\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Bookings($input: BookingsConnectionInputArgs!) {\n    bookings(input: $input) {\n      totalCount\n      results {\n        id\n        ...BookingsTableRow\n      }\n    }\n  }\n"): (typeof documents)["\n  query Bookings($input: BookingsConnectionInputArgs!) {\n    bookings(input: $input) {\n      totalCount\n      results {\n        id\n        ...BookingsTableRow\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CancelBookingDialog($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      cancellationOptions\n    }\n  }\n"): (typeof documents)["\n  query CancelBookingDialog($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      cancellationOptions\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CancelBooking($input: CancelBookingInput!) {\n    cancelBooking(input: $input) {\n      booking {\n        id\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CancelBooking($input: CancelBookingInput!) {\n    cancelBooking(input: $input) {\n      booking {\n        id\n        status\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ContactPersonTableRow on ContactPerson {\n    id\n    firstName\n    lastName\n    email\n    phone\n  }\n"): (typeof documents)["\n  fragment ContactPersonTableRow on ContactPerson {\n    id\n    firstName\n    lastName\n    email\n    phone\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CustomerTableRow on Customer {\n    id\n    firstName\n    lastName\n    email\n    phone\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n  }\n"): (typeof documents)["\n  fragment CustomerTableRow on Customer {\n    id\n    firstName\n    lastName\n    email\n    phone\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteContactPerson($id: ID!, $signature: String) {\n    deleteContactPerson(id: $id, signature: $signature) {\n      order {\n        id\n        contactPersons {\n          id\n          firstName\n          lastName\n          email\n          phone\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteContactPerson($id: ID!, $signature: String) {\n    deleteContactPerson(id: $id, signature: $signature) {\n      order {\n        id\n        contactPersons {\n          id\n          firstName\n          lastName\n          email\n          phone\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PaymentsInfo on Booking {\n    payments {\n      id\n      state\n      amount\n      provider\n      externalRef\n      externalUrl\n      cancellationOptions\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  fragment PaymentsInfo on Booking {\n    payments {\n      id\n      state\n      amount\n      provider\n      externalRef\n      externalUrl\n      cancellationOptions\n      updatedAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CancelPayment($id: ID!, $option: CancelOption!) {\n    cancelPayment(id: $id, option: $option) {\n      payment {\n        id\n        state\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CancelPayment($id: ID!, $option: CancelOption!) {\n    cancelPayment(id: $id, option: $option) {\n      payment {\n        id\n        state\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ResendMailDropdown on Booking {\n    id\n    status\n    reservation {\n      id\n      accepted\n    }\n    order {\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment ResendMailDropdown on Booking {\n    id\n    status\n    reservation {\n      id\n      accepted\n    }\n    order {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EnqueueMailer($input: EnqueueMailerInput!) {\n    enqueueMailer(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation EnqueueMailer($input: EnqueueMailerInput!) {\n    enqueueMailer(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UncancelBooking($input: UncancelBookingInput!) {\n    uncancelBooking(input: $input) {\n      booking {\n        id\n        status\n        reservation {\n          id\n          accepted\n          sequence\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UncancelBooking($input: UncancelBookingInput!) {\n    uncancelBooking(input: $input) {\n      booking {\n        id\n        status\n        reservation {\n          id\n          accepted\n          sequence\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateContactPerson($input: ContactPersonInput!) {\n    createContactPerson(input: $input) {\n      order {\n        id\n        contactPersons {\n          id\n          firstName\n          lastName\n          email\n          phone\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateContactPerson($input: ContactPersonInput!) {\n    createContactPerson(input: $input) {\n      order {\n        id\n        contactPersons {\n          id\n          firstName\n          lastName\n          email\n          phone\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query updateBookingInfo($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      firstName\n      lastName\n      dob\n      gender\n      pastVisits\n      friends\n      notes\n      sweaterSize\n      creditLimit\n      price\n      discount\n    }\n  }\n"): (typeof documents)["\n  query updateBookingInfo($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      firstName\n      lastName\n      dob\n      gender\n      pastVisits\n      friends\n      notes\n      sweaterSize\n      creditLimit\n      price\n      discount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateBooking($id: ID!, $input: UpdateBookingInput!) {\n    updateBooking(id: $id, input: $input) {\n      booking {\n        ...BookingInfo\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateBooking($id: ID!, $input: UpdateBookingInput!) {\n    updateBooking(id: $id, input: $input) {\n      booking {\n        ...BookingInfo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UpdateCustomerData on Customer {\n    id\n    firstName\n    lastName\n    email\n    phone\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n  }\n"): (typeof documents)["\n  fragment UpdateCustomerData on Customer {\n    id\n    firstName\n    lastName\n    email\n    phone\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UpdateCustomerData($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      order {\n        id\n        customer {\n          id\n          ...UpdateCustomerData\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query UpdateCustomerData($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      order {\n        id\n        customer {\n          id\n          ...UpdateCustomerData\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateCustomer($orderId: ID!, $input: CustomerInput!) {\n    updateCustomer(orderId: $orderId, input: $input) {\n      customer {\n        id\n        ...UpdateCustomerData\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCustomer($orderId: ID!, $input: CustomerInput!) {\n    updateCustomer(orderId: $orderId, input: $input) {\n      customer {\n        id\n        ...UpdateCustomerData\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query changeTermDialog($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      term {\n        id\n        season {\n          id\n          terms{\n            id\n            name\n            acceptedParticipantCount\n            maxParticipants\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query changeTermDialog($bookingId: ID!) {\n    booking(id: $bookingId) {\n      id\n      term {\n        id\n        season {\n          id\n          terms{\n            id\n            name\n            acceptedParticipantCount\n            maxParticipants\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateTermBooking($input: ChangeTermInput!) {\n    changeTerm(input: $input) {\n      booking {\n        id\n        status\n        reservation {\n          id\n          accepted\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateTermBooking($input: ChangeTermInput!) {\n    changeTerm(input: $input) {\n      booking {\n        id\n        status\n        reservation {\n          id\n          accepted\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RequestOtp($input: OtpInput!) {\n    requestOtp(input: $input) {\n      viewer {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RequestOtp($input: OtpInput!) {\n    requestOtp(input: $input) {\n      viewer {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Login($input: LoginInput!) {\n    login(input: $input) {\n      viewer {\n        id\n        user {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Login($input: LoginInput!) {\n    login(input: $input) {\n      viewer {\n        id\n        user {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query EditSeason($id: ID!) {\n    season(id: $id) {\n      id\n      name\n      registrationOpensAt\n      canSetSweaterSize\n      canSetCreditLimit\n    }\n  }\n"): (typeof documents)["\n  query EditSeason($id: ID!) {\n    season(id: $id) {\n      id\n      name\n      registrationOpensAt\n      canSetSweaterSize\n      canSetCreditLimit\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSeason($id: ID!, $input: SeasonInput!) {\n    updateSeason(id: $id, input: $input) {\n      season {\n        id\n        name\n        registrationOpensAt\n        canSetSweaterSize\n        canSetCreditLimit\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSeason($id: ID!, $input: SeasonInput!) {\n    updateSeason(id: $id, input: $input) {\n      season {\n        id\n        name\n        registrationOpensAt\n        canSetSweaterSize\n        canSetCreditLimit\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateSeason($input: SeasonInput!) {\n    createSeason(input: $input) {\n      season {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSeason($input: SeasonInput!) {\n    createSeason(input: $input) {\n      season {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Season($seasonId: ID!) {\n    season(id: $seasonId) {\n      id\n      name\n      registrationOpensAt\n      active\n      canSetSweaterSize\n      canSetCreditLimit\n    }\n  }\n"): (typeof documents)["\n  query Season($seasonId: ID!) {\n    season(id: $seasonId) {\n      id\n      name\n      registrationOpensAt\n      active\n      canSetSweaterSize\n      canSetCreditLimit\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ActivateSeason($id: ID!) {\n    activateSeason(id: $id) {\n      season {\n        id\n        active\n      }\n    }\n  }\n\n"): (typeof documents)["\n  mutation ActivateSeason($id: ID!) {\n    activateSeason(id: $id) {\n      season {\n        id\n        active\n      }\n    }\n  }\n\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SeasonsTable {\n    seasons {\n      id\n      name\n      registrationOpensAt\n      active\n    }\n  }\n"): (typeof documents)["\n  query SeasonsTable {\n    seasons {\n      id\n      name\n      registrationOpensAt\n      active\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EnqueueSeasonMailers($input: EnqueueSeasonMailersInput!) {\n    enqueueSeasonMailers(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation EnqueueSeasonMailers($input: EnqueueSeasonMailersInput!) {\n    enqueueSeasonMailers(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query EditTerm($id: ID!) {\n    term(id: $id) {\n      id\n      name\n      startsAt\n      endsAt\n      price\n      deposit\n      targetGroup\n      maxParticipants\n      hidden\n    }\n  }\n"): (typeof documents)["\n  query EditTerm($id: ID!) {\n    term(id: $id) {\n      id\n      name\n      startsAt\n      endsAt\n      price\n      deposit\n      targetGroup\n      maxParticipants\n      hidden\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateTerm($id: ID!, $input: TermInput!) {\n    updateTerm(id: $id, input: $input) {\n      term {\n        id\n        name\n        startsAt\n        endsAt\n        price\n        deposit\n        targetGroup\n        maxParticipants\n        hidden\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateTerm($id: ID!, $input: TermInput!) {\n    updateTerm(id: $id, input: $input) {\n      term {\n        id\n        name\n        startsAt\n        endsAt\n        price\n        deposit\n        targetGroup\n        maxParticipants\n        hidden\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateTerm($input: TermInput!) {\n    createTerm(input: $input) {\n      term {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTerm($input: TermInput!) {\n    createTerm(input: $input) {\n      term {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Terms($seasonId: ID!) {\n    terms(seasonId: $seasonId) {\n      id\n      name\n      startsAt\n      endsAt\n      maxParticipants\n      acceptedParticipantCount\n      totalParticipantCount\n      hidden\n    }\n  }\n"): (typeof documents)["\n  query Terms($seasonId: ID!) {\n    terms(seasonId: $seasonId) {\n      id\n      name\n      startsAt\n      endsAt\n      maxParticipants\n      acceptedParticipantCount\n      totalParticipantCount\n      hidden\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EnqueueTermMailers($input: EnqueueTermMailersInput!) {\n    enqueueTermMailers(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation EnqueueTermMailers($input: EnqueueTermMailersInput!) {\n    enqueueTermMailers(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserFormQuery {\n    availableUserRoles\n    activeTerms {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query UserFormQuery {\n    availableUserRoles\n    activeTerms {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query EditUser($id: ID!) {\n    user(id: $id) {\n      id\n      name\n      email\n      phone\n      active\n      roles\n      terms {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query EditUser($id: ID!) {\n    user(id: $id) {\n      id\n      name\n      email\n      phone\n      active\n      roles\n      terms {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUser($id: ID!, $input: UserInput!) {\n    updateUser(id: $id, input: $input) {\n      user {\n        id\n        name\n        email\n        phone\n        active\n        roles\n        terms {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUser($id: ID!, $input: UserInput!) {\n    updateUser(id: $id, input: $input) {\n      user {\n        id\n        name\n        email\n        phone\n        active\n        roles\n        terms {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateUser($input: UserInput!) {\n    createUser(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateUser($input: UserInput!) {\n    createUser(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Users($input: UsersConnectionInputArgs!) {\n    users(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        email\n        phone\n        active\n        roles\n      }\n    }\n  }\n"): (typeof documents)["\n  query Users($input: UsersConnectionInputArgs!) {\n    users(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        email\n        phone\n        active\n        roles\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;