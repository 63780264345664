import { Link } from 'react-router-dom'
import { useMutation } from 'urql'
import { DotsHorizontalIcon } from '@radix-ui/react-icons'
import { graphql } from '@/__generated__/gql'
import { BookingsTableRowFragment } from '@/__generated__/gql/graphql'
import { config } from '@/config'
import { formatDate } from '@/utils/format-date'
import { phrases } from '@/utils/phrases'
import { cn } from '@/utils/cn'
import { TableRow, TableCell } from '@/components/ui/table'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { useViewer } from '@/components/viewer-context-provider'

export const bookingsTableRowDocument = graphql(`
  fragment BookingsTableRow on Booking {
    id
    number
    status
    firstName
    lastName
    createdAt
    dob
    pastVisits
    sweaterSize
    discount
    isInvoiced
    term {
      id
      name
      targetGroup
      startsAt
      endsAt
    }
  }
`)

const setDiscountDocument = graphql(`
  mutation SetBookingDiscount($id: ID!, $input: UpdateBookingInput!) {
    updateBooking(id: $id, input: $input) {
      booking {
        id
        discount
      }
    }
  }
`)

type BookingRowProps = {
  booking: BookingsTableRowFragment
}

export const BookingTableRow = ({ booking }: BookingRowProps) => {
  const viewer = useViewer()
  const { toast } = useToast()
  const [{ fetching }, addDiscount] = useMutation(setDiscountDocument)

  const isAdmin = viewer.hasRole('admin')

  const setDiscount = async (discount: number) => {
    const { error } = await addDiscount({
      id: booking.id,
      input: { discount },
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Rabatt oppdatert!',
      description: 'Rabatt er oppdatert på bookingen',
      variant: 'default',
    })
  }

  return (
    <TableRow>
      <TableCell>
        <Link to={`/bookings/${booking.id}`}>{booking.number}</Link>
      </TableCell>
      <TableCell>{booking.firstName}</TableCell>
      <TableCell>{booking.lastName}</TableCell>
      <TableCell>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>{booking.term.name}</TooltipTrigger>
            <TooltipContent>
              {formatDate(booking.term.startsAt)} til{' '}
              {formatDate(booking.term.endsAt)}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </TableCell>
      <TableCell>
        <span
          className={cn(
            booking.status === 'canceled' && 'text-red-600',
            booking.status === 'accepted' && 'text-green-700'
          )}
        >
          {phrases.bookingStatuses[booking.status]}
        </span>
      </TableCell>
      <TableCell>{formatDate(booking.dob, { style: 'year' })}</TableCell>
      <TableCell>{booking.pastVisits}</TableCell>
      <TableCell>{booking.sweaterSize?.toUpperCase()}</TableCell>
      <TableCell>{formatDate(booking.createdAt)}</TableCell>
      <TableCell>{booking.discount}%</TableCell>
      <TableCell>{booking.isInvoiced ? 'Ja' : 'Nei'}</TableCell>
      <TableCell className="py-1">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="sm">
              <DotsHorizontalIcon className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem>
              <Link to={`/bookings/${booking.id}`}>Se booking</Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            {isAdmin && booking.discount === 0 && (
              <DropdownMenuItem
                disabled={fetching}
                onClick={() => setDiscount(config.siblingDiscount)}
              >
                Legg til søskenrabatt
              </DropdownMenuItem>
            )}
            {isAdmin && booking.discount > 0 && (
              <DropdownMenuItem
                disabled={fetching}
                onClick={() => setDiscount(0)}
              >
                Fjern søskenrabatt
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  )
}
