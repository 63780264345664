import { useMutation, useQuery } from 'urql'
import { useNavigate, useParams } from 'react-router-dom'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { FormValues, UserForm } from './components/user-form'

const editUserDocument = graphql(`
  query EditUser($id: ID!) {
    user(id: $id) {
      id
      name
      email
      phone
      active
      roles
      terms {
        id
      }
    }
  }
`)

const updateUserDocument = graphql(`
  mutation UpdateUser($id: ID!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      user {
        id
        name
        email
        phone
        active
        roles
        terms {
          id
        }
      }
    }
  }
`)

export const EditUser = () => {
  const navigate = useNavigate()
  const params = useParams<'userId'>()
  const { toast } = useToast()
  const [{ data, fetching, error }] = useQuery({
    query: editUserDocument,
    variables: { id: params.userId! },
  })
  const [{ fetching: submitting }, updateUser] = useMutation(updateUserDocument)

  const onClose = () => navigate('..')
  const onSubmit = async (values: FormValues) => {
    const { error } = await updateUser({
      id: params.userId!,
      input: {
        ...values,
        userRoles: values.userRoles.map((role) => ({ role })),
        userTerms: values.userTerms.map((termId) => ({ termId })),
      },
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Bruker endret',
      description: 'Brukeren ble endret',
      variant: 'default',
    })
    onClose()
  }

  const loading = fetching || submitting

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Endre bruker</DialogTitle>
          <DialogDescription />
        </DialogHeader>
        {fetching && <Loading />}
        {error && <QueryError error={error} />}
        {data && (
          <UserForm
            onSubmit={onSubmit}
            defaultValues={{
              name: data.user.name,
              email: data.user.email,
              phone: data.user.phone,
              active: data.user.active,
              userRoles: data.user.roles,
              userTerms: data.user.terms.map((term) => term.id),
            }}
          />
        )}

        <DialogFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            Avbryt
          </Button>
          <Button form="user-form" type="submit" disabled={loading}>
            Lagre
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
