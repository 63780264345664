import { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useMutation, useQuery } from 'urql'
import { DotsHorizontalIcon, EyeOpenIcon } from '@radix-ui/react-icons'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import { formatDate } from '@/utils/format-date'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { WarningAlert } from '@/components/warning-alert'
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { TableLayout } from '@/components/table-layout'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { SendCustomEmailDialog } from '@/components/send-custom-email-dialog'
import { useToast } from '@/components/ui/use-toast'

const seasonsDocument = graphql(`
  query SeasonsTable {
    seasons {
      id
      name
      registrationOpensAt
      active
    }
  }
`)

const enqueueSeasonMailersDocument = graphql(`
  mutation EnqueueSeasonMailers($input: EnqueueSeasonMailersInput!) {
    enqueueSeasonMailers(input: $input) {
      success
    }
  }
`)

export const Seasons = () => {
  const { toast } = useToast()
  const [emailSeasonId, setEmailSeasonId] = useState<string | null>(null)
  const [{ data, fetching, error }, refetch] = useQuery({
    query: seasonsDocument,
  })
  const [{ fetching: submitting }, enqueueSeasonMailers] = useMutation(
    enqueueSeasonMailersDocument
  )

  const seasons = data?.seasons || []

  const handleEnqueueSeasonMailers = async (
    seasonIds: string[],
    mailer: string,
    args?: string[]
  ) => {
    const { error } = await enqueueSeasonMailers({
      input: { seasonIds, mailer, args },
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'E-post sendt',
      description: 'E-post er sendt til bekreftede bookinger i sesongen.',
      variant: 'default',
    })
    setEmailSeasonId(null)
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <h1 className="text-4xl font-bold leading-loose">Sesonger</h1>
        <Button asChild>
          <Link to="new">Ny sesong +</Link>
        </Button>
      </div>

      <TableLayout>
        <TableHeader>
          <TableRow>
            <TableHead>#</TableHead>
            <TableHead>Påmeldingsstart</TableHead>
            <TableHead>Aktiv</TableHead>
            <TableHead className="w-[50px]" />
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>
          {seasons.map((season) => (
            <TableRow key={season.id}>
              <TableCell>{season.name}</TableCell>
              <TableCell>
                {formatDate(season.registrationOpensAt, { style: 'time' })}
              </TableCell>
              <TableCell>{season.active ? 'Ja' : 'Nei'}</TableCell>
              <TableCell>
                <Button asChild variant="link">
                  <Link to={`/seasons/${season.id}`}>
                    <EyeOpenIcon className="h-4 w-4" />
                  </Link>
                </Button>
              </TableCell>
              <TableCell className="py-1">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="sm">
                      <DotsHorizontalIcon className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem
                      disabled={submitting}
                      onClick={() => setEmailSeasonId(season.id)}
                    >
                      Send epost...
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableLayout>

      {fetching && <Loading />}
      {error && <QueryError error={error} />}
      {seasons.length === 0 && !fetching && !error && (
        <WarningAlert
          title="Ingen resultater"
          description="Ingen sesonger ble funnet."
        />
      )}

      {emailSeasonId && (
        <SendCustomEmailDialog
          description={seasons.find((s) => s.id === emailSeasonId)?.name}
          submitting={submitting}
          onClose={() => setEmailSeasonId(null)}
          onSubmit={(data) => {
            handleEnqueueSeasonMailers([emailSeasonId], 'customMessageMailer', [
              data.subject,
              data.body,
            ])
          }}
        />
      )}

      <Outlet context={{ refetch }} />
    </>
  )
}
