import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { toast } from '@/components/ui/use-toast'
import { BookingForm, FormValues } from './components/booking-form'

const updateBookingInfoDocument = graphql(`
  query updateBookingInfo($bookingId: ID!) {
    booking(id: $bookingId) {
      id
      firstName
      lastName
      dob
      gender
      pastVisits
      friends
      notes
      sweaterSize
      creditLimit
      price
      discount
    }
  }
`)

const updateBookingDocument = graphql(`
  mutation updateBooking($id: ID!, $input: UpdateBookingInput!) {
    updateBooking(id: $id, input: $input) {
      booking {
        ...BookingInfo
      }
    }
  }
`)

export const UpdateBooking = () => {
  const params = useParams<'bookingId'>()
  const navigate = useNavigate()
  const [{ data, fetching, error }] = useQuery({
    query: updateBookingInfoDocument,
    variables: { bookingId: params.bookingId! },
  })
  const [{ fetching: submitting }, updateBooking] = useMutation(
    updateBookingDocument
  )
  const onClose = () => navigate('..')
  const onSubmit = async (input: FormValues) => {
    const { error } = await updateBooking({
      id: params.bookingId!,
      input,
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Deltaker redigert!',
      variant: 'default',
    })
    onClose()
  }

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        {fetching && <Loading />}
        {error && <QueryError error={error} />}
        {data && (
          <>
            <DialogHeader>
              <DialogTitle>Rediger deltaker</DialogTitle>
              <DialogDescription />
            </DialogHeader>
            <BookingForm defaultValues={data.booking} onSubmit={onSubmit} />
            <DialogFooter>
              <Button type="button" variant="secondary" onClick={onClose}>
                Avbryt
              </Button>
              <Button form="form-booking" type="submit" disabled={submitting}>
                Bekreft endring
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
