import { Link } from 'react-router-dom'
import { Pencil2Icon } from '@radix-ui/react-icons'
import { graphql } from '@/__generated__/gql'
import { BookingInfoFragment } from '@/__generated__/gql/graphql'
import { formatDate } from '@/utils/format-date'
import { formatCurrency } from '@/utils/format-currency'
import { phrases } from '@/utils/phrases'
import { Button } from '@/components/ui/button'
import { useViewer } from '@/components/viewer-context-provider'
import { FieldWrapper } from './field-wrapper'

export const attendeeInfoDocument = graphql(`
  fragment AttendeeInfo on Booking {
    id
    status
    firstName
    lastName
    dob
    pastVisits
    gender
    friends
    notes
    sweaterSize
    price
    discount
    creditLimit
    isFullyPaid
    createdAt
    updatedAt
    activeBookingExport {
      id
      state
      invoicePaidAt
      creditNoteRef
    }
    term {
      id
      name
      startsAt
      endsAt
    }
    reservation {
      id
      sequence
    }
  }
`)

export type AttendeeInfoProps = {
  booking: BookingInfoFragment
}

export const AttendeeInfo = ({ booking }: AttendeeInfoProps) => {
  const viewer = useViewer()

  const genderLabel =
    booking.gender === 'male'
      ? 'Gutt'
      : booking.gender === 'female'
        ? 'Jente'
        : 'Annet'

  return (
    <div>
      <div className="w-full mb-4 flex items-center">
        <h2 className="font-bold text-xl">Deltaker</h2>
        {viewer.hasRole('admin') && (
          <Button asChild size="sm" className="ml-4">
            <Link to="./update-booking">
              <Pencil2Icon />
            </Link>
          </Button>
        )}
      </div>
      <div className="flex flex-wrap m-2">
        <FieldWrapper label="Navn">
          {booking.firstName} {booking.lastName}
        </FieldWrapper>
        <FieldWrapper label="Fødelsdato">
          {formatDate(booking.dob)}
        </FieldWrapper>
        <FieldWrapper label="Kjønn">{genderLabel}</FieldWrapper>
        <FieldWrapper label="Periode">
          {`${booking.term.name} - ${formatDate(
            booking.term.startsAt
          )} til ${formatDate(booking.term.endsAt)}`}
        </FieldWrapper>
        <FieldWrapper label="Tidligere deltatt år">
          {booking.pastVisits}
        </FieldWrapper>
        <FieldWrapper label="Genserstrørrelse">
          {booking.sweaterSize?.toLocaleUpperCase() || 'Nei'}
        </FieldWrapper>
        <FieldWrapper label="Ønsker å bo med">{booking.friends}</FieldWrapper>
        <FieldWrapper label="Særlige opplysninger">
          {booking.notes}
        </FieldWrapper>
        <FieldWrapper label="Bookingsdato">
          {formatDate(booking.createdAt, { style: 'time' })}
        </FieldWrapper>
        <FieldWrapper label="Siste oppdatering">
          {formatDate(booking.updatedAt, { style: 'time' })}
        </FieldWrapper>
        <FieldWrapper label="Status">
          {phrases.bookingStatuses[booking.status]}
        </FieldWrapper>
        <FieldWrapper label="Reservasjonsnummer">
          {booking.reservation.sequence}
        </FieldWrapper>
        <FieldWrapper label="Kioskpenger">
          {booking.creditLimit ? formatCurrency(booking.creditLimit) : '-'}
        </FieldWrapper>
        <FieldWrapper label="Pris">
          {formatCurrency(booking.price)}
        </FieldWrapper>
        <FieldWrapper label="Rabatt">{booking.discount}%</FieldWrapper>
        <FieldWrapper label="Betalt">
          {booking.isFullyPaid ? 'Ja' : 'Nei'}
        </FieldWrapper>
        {booking.activeBookingExport?.state === 'credited' && (
          <FieldWrapper label="Kreditert">Ja</FieldWrapper>
        )}
      </div>
    </div>
  )
}
