import { useMutation } from 'urql'
import { DotsHorizontalIcon } from '@radix-ui/react-icons'
import { graphql } from '@/__generated__/gql'
import { BookingExportsInfoFragment } from '@/__generated__/gql/graphql'
import { formatDate } from '@/utils/format-date'
import { formatCurrency } from '@/utils/format-currency'
import { phrases } from '@/utils/phrases'
import { TableLayout } from '@/components/table-layout'
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useToast } from '@/components/ui/use-toast'

export const bookingExportsInfoDocument = graphql(`
  fragment BookingExportsInfo on Booking {
    bookingExports {
      id
      state
      exportedAmount
      invoiceTotal
      invoicePaidAt
      invoiceNumber
      createdAt
    }
  }
`)

const creditBookingExportDocument = graphql(`
  mutation CreditBookingExport($id: ID!) {
    creditBookingExport(id: $id) {
      bookingExport {
        id
        state
      }
    }
  }
`)

export type BookingExportsInfoProps = {
  bookingExports: BookingExportsInfoFragment['bookingExports']
}

export const BookingExportsInfo = ({
  bookingExports,
}: BookingExportsInfoProps) => {
  const { toast } = useToast()
  const [{ fetching }, creditBookingExport] = useMutation(
    creditBookingExportDocument
  )

  const handleCreditBookingExport = async (id: string) => {
    if (!confirm('Er du sikker på at du vil kreditere denne fakturaen?')) {
      return
    }

    const { error } = await creditBookingExport({ id })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Faktura krediteres',
      description:
        'Faktura lagt i kø for kreditering. Oppdater siden om litt for status.',
      variant: 'default',
    })
  }

  return (
    <div>
      <h2 className="font-bold text-xl mb-2">Fakturering</h2>
      <TableLayout>
        <TableHeader>
          <TableRow>
            <TableHead>Dato</TableHead>
            <TableHead>Fakturanummer</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Totalbeløp</TableHead>
            <TableHead>Å betale</TableHead>
            <TableHead>Betalt</TableHead>
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>
          {bookingExports.map((bookingExport) => (
            <TableRow key={bookingExport.id}>
              <TableCell>
                {formatDate(bookingExport.createdAt, { style: 'time' })}
              </TableCell>
              <TableCell>{bookingExport.invoiceNumber ?? '-'}</TableCell>
              <TableCell>{bookingExport.state}</TableCell>
              <TableCell>
                {bookingExport.exportedAmount
                  ? formatCurrency(bookingExport.exportedAmount)
                  : '-'}
              </TableCell>
              <TableCell>
                {bookingExport.invoiceTotal
                  ? formatCurrency(bookingExport.invoiceTotal)
                  : '-'}
              </TableCell>
              <TableCell>
                {bookingExport.invoicePaidAt
                  ? formatDate(bookingExport.invoicePaidAt)
                  : '-'}
              </TableCell>
              <TableCell className="py-1">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="sm">
                      <DotsHorizontalIcon className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {bookingExport?.state === 'invoiced' && (
                      <DropdownMenuItem
                        disabled={fetching}
                        onClick={() =>
                          handleCreditBookingExport(bookingExport.id)
                        }
                      >
                        Krediter
                      </DropdownMenuItem>
                    )}
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableLayout>
    </div>
  )
}
