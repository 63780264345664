import { useMutation } from 'urql'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { FormValues, UserForm } from './components/user-form'

const createUserDocument = graphql(`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      user {
        id
      }
    }
  }
`)

export const NewUser = () => {
  const { refetch } = useOutletContext<{ refetch: () => void }>()
  const navigate = useNavigate()
  const { toast } = useToast()
  const [_, createUser] = useMutation(createUserDocument)

  const onClose = () => navigate('..')
  const onSubmit = async (values: FormValues) => {
    const { error } = await createUser({
      input: {
        ...values,
        userRoles: values.userRoles.map((role) => ({ role })),
        userTerms: values.userTerms.map((termId) => ({ termId })),
      },
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Bruker opprettet',
      description: 'Ny bruker ble opprettet',
      variant: 'default',
    })
    refetch()
    onClose()
  }

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Ny bruker</DialogTitle>
          <DialogDescription />
        </DialogHeader>

        <UserForm
          onSubmit={onSubmit}
          defaultValues={{
            name: '',
            email: '',
            phone: null,
            active: true,
          }}
        />

        <DialogFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            Avbryt
          </Button>
          <Button form="user-form" type="submit">
            Opprett
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
